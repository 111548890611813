//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";
@import "typo";

// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

//---------------------------------------------
// Navigation
//---------------------------------------------

.nav-main {
  background-color: #00386c!important;
}

#nav-main {
  justify-content: center;
}
#nav-main ul li a {
  text-transform: uppercase;
  font-family: Baloo, arial, serif;
  font-weight: normal;
  color: #fff;
  font-size: 14px;
}

#nav-main2 {
  justify-content: center;
}

#nav-main2 ul li a {
  text-transform: uppercase;
  font-family: Baloo, arial, serif;
  font-weight: normal;
  color: #fff;
  font-size: 14px;
  display: inline-block !important;
}

#nav-main2 .navbar-nav li{
  display: block !important;
  float: left !important;
}

.navbar-nav2{
  display:block !important;
}

//---------------------------------------------
// Header
//---------------------------------------------

header {
  //padding-top: 25px;
  background-position: bottom center;
  background-repeat: no-repeat;
  min-height: unset;
  margin-bottom: unset;
}
@include media-breakpoint-up(xs) {
  header {
    min-height: unset;
    margin-bottom: unset;
  }
  header .header-container {
    background-color: #00386c;
    padding-bottom: 25px;
  }
}
@include media-breakpoint-up(sm) {
  header {
    background-image: url(../images/decoration/colibri-header.svg);
    min-height: 625px;
    margin-bottom: -400px;
  }
  header .header-container {
    background-color: unset;
  }
}
@include media-breakpoint-up(md) {
  header {
    background-image: url(../images/decoration/colibri-header.svg);
    min-height: 727px;
    margin-bottom: -450px;
  }
  header .header-container {
    background-color: unset;
  }
}
@include media-breakpoint-up(xl) {
  header {
    background-image: url(../images/decoration/colibri-header.svg);
    min-height: 727px;
    margin-bottom: -450px;
  }
  header .header-container {
    background-color: unset;
  }
}

.header-container {
  position: relative;
}

#logo img {
  max-height: 233px;
  padding-left: 50px;
  padding-right: 50px;
}

.contact-us-box {
  font-family: Baloo, arial, serif;
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  //margin-top: -40px;
}

//---------------------------------------------
// Choose Site
//---------------------------------------------

.link-choose {
  transition: transform .2s;
  max-width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 216%;
  font-family: 'Baloo', arial, serif;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 27px;
  border-radius: 20px;
  border-style: solid;
  border-left-width: 8px;
  border-right-width: 8px;
  border-top-width: 3px;
  border-bottom-width: 3px;
  height: 140px;
  position: relative;
  margin: 0 auto 35px;
  padding: 5px;
}

.link-choose:hover {
  color: rgb(0, 56, 108);
  transform: scale(1.04);
}

.link-choose:hover::after {
  transform: scale(0.80);
}
@include media-breakpoint-up(md) {
  .link-choose {
    font-size: 274%;
  }
}
.link-choose::after {
  display: block;
  content: " ";
  width: 150px;
  height: 50px;
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  transition: transform .2s;
}

.link-b2b {
  background-color: rgb(245, 130, 32);
  color: #fff;
  border-color: rgb(0, 56, 108);
}
//.link-b2b::after {
//  background-image: url(../images/decoration/vektoros-szirom-2.svg);
//  top: 141px;
//  right: -15px;
//}
@include media-breakpoint-up(md) {
  .link-b2b::after {
    top: 138px;
    right: -95px;
  }
}

.link-b2c {
  background-color: rgb(0, 172, 200);
  color: #fff;
  border-color: rgb(0, 56, 108);
}
.link-b2c::after {
  background-image: url(../images/decoration/vektoros-szirom-2.svg);
  top: 141px;
  right: -15px;
}
@include media-breakpoint-up(md) {
  .link-b2c::after {
    top: 138px;
    right: -95px;
  }
}

//---------------------------------------------
// Contact us
//---------------------------------------------

.decor-title.title-1 {
  background-image: url(../images/decoration/title-a.svg);
  height: 362px;
  background-position: center center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.decor-title.title-1 .title {
  font-family: Baloo, Arial, serif;
  text-transform: uppercase;
  color: rgb(0, 56, 108);
  font-size: 45px;
}
@include media-breakpoint-up(md) {
  .decor-title.title-1 .title {
    font-size: 4vw;
  }
}
@include media-breakpoint-up(xl) {
  .decor-title.title-1 .title {
    font-size: 55px;
  }
}
.decor-title.title-1 .description {
  font-family: Baloo, Arial, serif;
  color: rgb(0, 56, 108);
  font-size: 19px;
  text-align: center;
}
@include media-breakpoint-up(md) {
  .decor-title.title-1 .description {
    font-size: 2vw;
  }
}
@include media-breakpoint-up(xl) {
  .decor-title.title-1 .description {
    font-size: 28px;
  }
}

.contact-us-list .accordion-button.collapsed {
  background-color: rgb(0,174,239);
  color: rgb(0,56,108);
  font-family: Baloo, Arial, serif;
  font-size: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.contact-us-list .accordion-button {
  background-color: rgb(0,56,108);
  color: #fff;
  font-family: Baloo, Arial, serif;
  font-size: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.contact-us-list .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' fill='%23000000'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Ctitle%3Eplus%3C/title%3E%3Cdesc%3ECreated with Sketch Beta.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' sketch:type='MSPage'%3E%3Cg id='Icon-Set-Filled' sketch:type='MSLayerGroup' transform='translate(-362.000000, -1037.000000)' fill='%2300386c'%3E%3Cpath d='M390,1049 L382,1049 L382,1041 C382,1038.79 380.209,1037 378,1037 C375.791,1037 374,1038.79 374,1041 L374,1049 L366,1049 C363.791,1049 362,1050.79 362,1053 C362,1055.21 363.791,1057 366,1057 L374,1057 L374,1065 C374,1067.21 375.791,1069 378,1069 C380.209,1069 382,1067.21 382,1065 L382,1057 L390,1057 C392.209,1057 394,1055.21 394,1053 C394,1050.79 392.209,1049 390,1049' id='plus' sketch:type='MSShapeGroup'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.contact-us-list .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 -12 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' fill='%23000000'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Ctitle%3Eminus%3C/title%3E%3Cdesc%3ECreated with Sketch Beta.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' sketch:type='MSPage'%3E%3Cg id='Icon-Set-Filled' sketch:type='MSLayerGroup' transform='translate(-414.000000, -1049.000000)' fill='%23ffffff'%3E%3Cpath d='M442,1049 L418,1049 C415.791,1049 414,1050.79 414,1053 C414,1055.21 415.791,1057 418,1057 L442,1057 C444.209,1057 446,1055.21 446,1053 C446,1050.79 444.209,1049 442,1049' id='minus' sketch:type='MSShapeGroup'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.contact-us-list .accordion-button:focus {
  z-index: 3;
  border-color: rgb(0,174,239);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0,56,108, 0.25);
}
.contact-us-list .accordion-item > .accordion-collapse > h2.accordion-header {
  margin-left: 20px;
  margin-right: 20px;
}

.container.contact-us {
  margin-top: 50px;
}

.contact-us-list .accordion-button span {
  display: block;
  width: 100%;
  text-align: center;
}

.contact-profile {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.contact-profile .avatar {
  border-radius: 100%;
  box-shadow: 0px 5px 16px rgba(0,0,0,.06), 0px 10px 9px rgba(0,0,0,.06);
  max-width: 170px;
  margin-bottom: 20px;
}
.contact-profile .name {
  font-family: Baloo, Arial, serif;
  text-transform: uppercase;
  color: rgb(0, 56, 108);
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 10px;
}
.contact-profile .position {
  font-family: Baloo, Arial, serif;
  color: #000;
  font-weight: normal;
  font-size: 18px;
  background-color: rgb(0, 172, 200);
  margin-bottom: 10px;
}
.contact-profile .email,
.contact-profile .telephone {
  font-family: Baloo, Arial, serif;
  font-size: 18px;
  color: rgb(0, 56, 108);
  display: block;
  text-decoration: none;
}

.contact-profile .email:hover,
.contact-profile .telephone:hover {
  color: rgb(245, 130, 32);
}

.container.contact-us {

}

//
// Contact form
//

.contact-form {
  background-color: #1eab4c;
}
.contact-form-title {
  text-align: center;
  font-family: Baloo, Arial, serif;
  text-transform: uppercase;
  color: rgb(0, 56, 108);
  font-size: 45px;
  margin-top: 25px;
  margin-bottom: 25px;
}
@include media-breakpoint-up(md) {
  .contact-form-title {
    font-size: 3vw;
  }
}
@include media-breakpoint-up(xl) {
  .contact-form-title {
    font-size: 54px;
  }
}

#contact-form .form-control {
  font-family: Baloo, Arial, serif;
  font-size: 18px;
  border-radius: 20px;
  min-height: 55px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5px;
}
#contact-form .form-switch label {
  font-family: Baloo, Arial, serif;
  color: rgb(0, 56, 108);
  font-size: 19px;
}
#contact-form .form-actions {
  text-align: center;
}
#contact-form .form-actions button {
  font-family: Baloo, Arial, serif;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 25px;
  border-radius: 20px;
  padding: 5px 50px;
  margin-top: 25px;
}
#contact-form .form-switch {
  margin-top: 15px;
  padding-left: 60px;
}
.contact-form::before {
  width: 100%;
  height: 247px;
  display: block;
  content: " ";
  background-image: url(../images/decoration/form-top.svg);
  background-repeat: no-repeat;
  background-position: top center;
  margin-bottom: -89px;
  background-color: #fff;
}
.contact-form::after {
  width: 100%;
  height: 389px;
  display: block;
  content: " ";
  background-image: url(../images/decoration/form-bottom.svg);
  background-repeat: no-repeat;
  background-position: top center;
  margin-top: -180px;
  background-color: #fff;
  margin-bottom: -155px;
}

//---------------------------------------------
// Jobs
//---------------------------------------------

.decor-title.title-2 .title {
  text-align: center;
  font-family: Baloo, Arial, serif;
  text-transform: uppercase;
  color: rgb(0, 56, 108);
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 0;
}
@include media-breakpoint-up(md) {
  .decor-title.title-2 .title {
    font-size: 4vw;
  }
}
@include media-breakpoint-up(xl) {
  .decor-title.title-2 .title {
    font-size: 60px;
  }
}
.decor-title.title-2 .title .decor {
  margin-right: -220px;
  margin-bottom: -20px;
  margin-top: -88px;
}
.decor-title.title-2 .description {
  text-align: center;
  font-family: Baloo, Arial, serif;
  color: rgb(0, 56, 108);
  font-size: 23px;
  margin-top: 20px;
  margin-bottom: 50px;
}

//---------------------------------------------
// About us
//---------------------------------------------


.about-us {
  background-color: rgb(178, 210, 52);
}
.about-us .about-us-title {
  text-align: right;
  font-family: Baloo, Arial, serif;
  text-transform: uppercase;
  color: rgb(0, 56, 108);
  font-size: 80px;
  margin-top: -140px;
  margin-bottom: 80px;
  padding-right: 25px;
}
.about-us::before {
  width: 100%;
  height: 364px;
  display: block;
  content: " ";
  background-image: url("../images/decoration/terv-hatter-5-top.svg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top center;
  margin-bottom: -40px;
}
.about-us::after {
  width: 100%;
  height: 275px;
  display: block;
  content: " ";
  background-image: url("../images/decoration/terv-hatter-5-bottom.svg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top center;
  margin-top: -100px;
}
.about-us .box-title {
  font-family: Baloo, Arial, serif;
  color: rgb(245, 130, 32);
  font-size: 34px;
}
.about-us .box-description {
  font-family: Baloo, Arial, serif;
  font-size: 22px;
}

.about-us-2 {
  background-color: rgb(98, 141, 169);
}
.about-us-2::before {
  width: 100%;
  height: 373px;
  display: block;
  content: " ";
  background-image: url("../images/decoration/terv-hatter-2-top.svg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top center;
  margin-bottom: -148px;
  margin-top: -7px;
}
.about-us-2::after {
  width: 100%;
  height: 458px;
  display: block;
  content: " ";
  background-image: url(../images/decoration/terv-hatter-2-bottom.svg);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top center;
  margin-top: -135px;
}
.about-us-2 .box-title {
  font-family: Baloo, Arial, serif;
  color: rgb(178, 210, 52);
  font-size: 34px;
}
.about-us-2 .box-description {
  font-family: Baloo, Arial, serif;
  font-size: 22px;
}

.decor-img-1 {
  border-radius: 100%;
  border: 6px solid #fff;
  margin-bottom: 50px;
  width: 100%;
  height: auto;
  max-width: 512px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@include media-breakpoint-up(xl) {
  .decor-img-1 {
    margin-top: -350px;
    width: 400px;
    height: 400px;
    text-align: left;
    margin-left: unset;
    margin-right: unset;
  }
}

.decor-img-2 {
  border-radius: 100%;
  border: 6px solid #fff;
  margin-top: -400px;
  margin-bottom: 45px;
  width: 100%;
  height: auto;
  max-width: 512px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

@include media-breakpoint-up(xl) {
  .decor-img-2 {
    width: 450px;
    height: 450px;
    margin-right: unset;
    margin-left: auto;
  }
}

.decor-img-3 {
  border-radius: 100%;
  border: 6px solid #fff;
  margin-top: 0;
  margin-bottom: -250px;
  width: 100%;
  height: auto;
  max-width: 512px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

@include media-breakpoint-up(xl) {
  .decor-img-3 {
    width: 450px;
    height: 450px;
    margin-top: -100px;
    margin-bottom: -300px;
    margin-right: auto;
    margin-left: unset;
  }
}

//---------------------------------------------
// Video
//---------------------------------------------

.decor-title.title-3 .title {
  text-align: center;
  font-family: Baloo, Arial, serif;
  text-transform: uppercase;
  color: #fb0223;
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}
@include media-breakpoint-up(md) {
  .decor-title.title-3 .title {
    font-size: 4vw;
  }
}
@include media-breakpoint-up(xl) {
  .decor-title.title-3 .title {
    font-size: 60px;
  }
}

//---------------------------------------------
// Opening hours
//---------------------------------------------

.nyitvatartas-text {
  text-align: center;
  font-size: 20px;
  margin-top: -25px;
  margin-bottom: 50px;
}

//---------------------------------------------
// Footer
//---------------------------------------------

.footer {
  background-color: rgb(245, 130, 32);
}
.footer::before {
  width: 100%;
  height: 458px;
  display: block;
  content: " ";
  background-image: url(../images/decoration/terv-hatter-1.svg);
  background-repeat: no-repeat;
  background-position: top center;
  margin-top: -135px;
  margin-bottom: -100px;
}
.footer h4 {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.footer .footer-contact-menu {
  list-style: none;
  padding-left: 5px;
}
.footer .footer-contact-menu li a {
  color: #fff;
  text-decoration: none;
}
.footer .footer-contact-menu li a:hover {
  color: #000;
}
.footer .footer-menu {
  list-style: none;
  padding-left: 5px;
}
.footer .footer-menu li a {
  color: #fff;
  text-decoration: none;
}
.footer .footer-menu li a:hover {
  color: #000;
}

//---------------------------------------------
// Other
//---------------------------------------------


.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-decor-1 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.callout-2 {
  font-family: Baloo, arial, serif;
  //font-size: 1vw;
  font-size: 12pt;
  color: rgb(0, 56, 108);
  text-transform: uppercase;
}

.callout-2 a {
  text-transform:none !important;
}

.callout-2-row {
  margin-top: 25px;
  margin-bottom: 50px;
}

.callout-3 {
  font-family: Baloo, arial, serif;
  color: rgb(0, 56, 108);
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 25px;
  padding-left: 5%;
  margin-right: 5%;
}

body {
  font-size: 18px;
}

.login-row {
  //margin-top: -150px;
  margin-bottom: 50px;
}

.login-title {
  font-size: 32px;
  font-family: 'Baloo', arial, serif;
  font-weight: normal;
  color: rgb(0, 56, 108);
}
@include media-breakpoint-up(md) {
  .login-title {
    font-size: 4.5vw;
  }
}
@include media-breakpoint-up(xl) {
  .login-title {
    font-size: 58px;
    margin-top: 40px;
  }
}


.category-row {
  margin-left: 10px;
  margin-right: 10px;
}

.category-title {
  font-family: Baloo, Arial, serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 28px;
  color: rgb(0, 56, 108);
  margin-bottom: 40px;
  line-height: 53px;
}
@include media-breakpoint-up(md) {
  .category-title {
    font-size: 4vw;
  }
}
@include media-breakpoint-up(xl) {
  .category-title {
    font-size: 34px;
  }
}

.category-link {
  text-decoration: none;
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  border-width: 2px;
  border-style: solid;
  transition: transform .2s;
}
.category-link:hover {
  transform: scale(1.1);
}
.category-link::before {
  width: 150px;
  height: 150px;
  content: " ";
  display: block;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
}
.category-link.odd {
  background-color: #fff;
  color: rgb(0, 56, 108);
  border-color: rgb(0, 56, 108);
}
.category-link.even {
  background-color: rgb(0, 56, 108);
  color: #fff;
  border-color: transparent;
}

.category-edesseg::before {
  background-image: url("../images/category/edesseg.svg");
}
.category-kavek::before {
  background-image: url("../images/category/kavek.svg");
}
.category-illatszer::before {
  background-image: url("../images/category/illatszer.svg");
}
.category-vegyiaru::before {
  background-image: url("../images/category/vegyiaru.svg");
}
.category-elelmiszerek::before {
  background-image: url("../images/category/elelmiszerek.svg");
}
.category-non-food-termekek::before {
  background-image: url("../images/category/non-food-termekek.svg");
}

//---------------------------------------------
// Hiding anti-SPAM input
//---------------------------------------------

#inputSubject {
  display: none;
}

//---------------------------------------------
// Modal
//---------------------------------------------

.modal.fade .modal-dialog {
  -webkit-transform: scale(0.1);
  -moz-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
  top: 300px;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.modal.fade.in .modal-dialog {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform: translate3d(0, -300px, 0);
  transform: translate3d(0, -300px, 0);
  opacity: 1;
}

.modal .btn-secondary {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

//---------------------------------------------
// Palyazat
//---------------------------------------------

a.palyazat {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
a.palyazat img {
  height: 100px;
}

@media only screen and (max-width: 1247px) {
  .contact-us-box {
    padding-right: 50px;
  }
}

/** 1545 **/
@media only screen and (max-width: 1230px) {
  .contact-us-box {
    padding-right: unset;
  }
  #nav-main {
    justify-content: unset;
  }
}

@include media-breakpoint-up(md) {
  a.palyazat img {
    height: 140px;
  }
}
@include media-breakpoint-up(xl) {
  a.palyazat img {
    height: 150px;
  }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";

/* Handle 125% and 150% Windows 10 Font Scaling on 96dpi monitors */
//@media (min-resolution: 120dpi) and (max-width: 1920px) {
//  header {
//    min-height: 664px;
//  }
//  .choose-site {
//    margin-top: -410px;
//  }
//  .login-row {
//    margin-bottom: 25px;
//  }
//  .contact-us-box {
//    padding-top: 12px;
//  }
//  .modal-body {
//    font-size: 16px;
//  }
//}

.app_info_text{
  font-size: 143%;
  color: #00386c;
  font-weight: bold;
  font-family: Baloo, arial, serif;
  margin-top: -55px;
}
.app_info_text2{
  font-size: 143%;
  color: #00386c;
  font-weight: bold;
  font-family: Baloo, arial, serif;
  margin-top: -15px;
}

.app_info{
  font-size: 143%;
  color: #00386c;
  font-weight: bold;
  font-family: Baloo, arial, serif;
}

.app_info_list{
  font-size: 107%;
  color: #00386c;
  font-weight: bold;
  font-family: Baloo, arial, serif;
}


.link-choose_android {
  background-color: #00aeef;
  transition: transform .2s;
  max-width: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80%;
  font-family: 'Baloo', arial, serif;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 27px;
  border-radius: 20px;
  border-style: solid;
  border-left-width: 8px;
  border-right-width: 8px;
  border-top-width: 3px;
  border-bottom-width: 3px;
  height: 72px;
  position: relative;
  margin: 0 auto 15px;
  padding: 5px;
  border-color: rgb(0, 56, 108) !important;
  color: rgb(255, 255, 255);
}

.link-choose_android:hover {
  color: rgb(0, 56, 108);
  transform: scale(1.04);
}

.link-choose_r {
  transition: transform .2s;
  max-width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 216%;
  font-family: 'Baloo', arial, serif;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 27px;
  border-radius: 20px;
  border-style: solid;
  border-left-width: 8px;
  border-right-width: 8px;
  border-top-width: 3px;
  border-bottom-width: 3px;
  height: 90px;
  position: relative;
  margin: 0 auto 35px;
  padding: 5px;
}

.link-choose_r:hover {
  color: rgb(0, 56, 108);
  transform: scale(1.04);
}

.link-choose_r:hover::after {
  transform: scale(0.80);
}
@include media-breakpoint-up(md) {
  .link-choose_r {
    font-size: 274%;
  }
}
.link-choose_r::after {
  display: block;
  content: " ";
  width: 150px;
  height: 50px;
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  transition: transform .2s;
}

.munka{
  font-weight: bold;
  text-decoration: underline;
}


// Nyelv választás menü

.nyelvvalasztas{
  //position:absolute;
  z-index: 2;
 //align-content: center;
}

.adatlap-statusz {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
